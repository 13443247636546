import { Spinner } from "office-ui-fabric-react";
import React from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { IInvitation } from "../models/Invitation";
import { IScope } from "../models/Scope";
import { IVendor } from "../models/Vendor";
import apiService from "../services/ApiService";

export interface INewInvitationDialogState {
    isValidated: boolean;
    invitation: IInvitation;
}

export interface INewInvitationDialogProps {
    show: boolean;
    vendor: IVendor;
    scopes: IScope[];
    onCancel: () => void;
    onSubmit: (invitation: IInvitation) => void;
}

export default class NewInvitationDialog extends React.Component<INewInvitationDialogProps, INewInvitationDialogState> {

    constructor(props: INewInvitationDialogProps) {
        super(props);

        this.state = {
            isValidated: false,
            invitation: {
                id: '',
                emailAddress: '',
                name: '',
                scopeId: '',
                status: 'Invited',
                vendorId: this.props.vendor.id
            }
        };
    }

    async componentDidMount() {
        this.handleScopesChange(this.props.scopes);
    }

    async componentWillReceiveProps(props: INewInvitationDialogProps) {
        this.handleScopesChange(props.scopes);
    }

    handleScopesChange(scopes: IScope[]) {
        let invitation: IInvitation = { ...this.state.invitation, scopeId: '' }

        if (scopes.length === 1) {
            invitation.scopeId = scopes[0].id;
        }

        this.setState({ invitation });
    }

    handleInputChange = async (event: any) => {
        // Create a copy of invitation
        let invitation = { ...this.state.invitation };


        switch (event.target.name) {
            case 'emailAddress':
                invitation.emailAddress = event.target.value;
                break;
            case 'name':
                invitation.name = event.target.value;
                break;
            case 'scope':
                invitation.scopeId = event.target.value;
                break;
        }

        this.setState({ ...this.state, invitation });
    }

    submit = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity()) {
            await apiService.inviteUser(this.state.invitation);
            this.props.onSubmit && this.props.onSubmit(this.state.invitation);
        }
    }

    render() {
        var {
            show,
            scopes,
            onCancel
        } = this.props;

        var {
            isValidated
        } = this.state

        return (
            <Modal
                show={show}
                onHide={onCancel}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        New Invitation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={isValidated} onSubmit={(e: any) => this.submit(e)}>
                        <Form.Group controlId="validationName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Name"
                                aria-describedby="inputGroupPrepend"
                                required
                                onChange={this.handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a name.
                                </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationEmailAddress">
                            <Form.Label>E-mail address</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    name="emailAddress"
                                    placeholder="E-mail address"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    onChange={this.handleInputChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter an e-mail address.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        {
                            scopes.length !== 1 &&
                            <Form.Group controlId="formScope">
                                <Form.Label>Project</Form.Label>
                                <div style={{ position: 'relative' }}>
                                    <Form.Control as="select"
                                        required
                                        name="scope"
                                        onChange={this.handleInputChange}>
                                        <option></option>
                                        {scopes.map(s =>
                                            (
                                                <option key={s.id} value={s.id}>{s.name}</option>
                                            )
                                        )}
                                    </Form.Control>
                                    {
                                        scopes.length === 0 &&
                                        <Spinner style={{ position: 'absolute', top: '9px', left: '9px' }} />
                                    }
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    Please select a Project
                            </Form.Control.Feedback>
                            </Form.Group>
                        }

                        <Form.Group as={Row}>
                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button type='submit' style={{ marginRight: '.25em' }}>Invite</Button>
                                <Button variant="secondary" style={{ marginLeft: '.25em' }} onClick={onCancel}>Cancel</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}