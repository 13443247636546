import { VendorType } from "./models/Vendor";

export const vendorTypes = [
    {
        "key": VendorType.Unknown,
        "text": "Unknown"
    },
    {
        "key": VendorType.Major,
        "text": "Major"
    },
    {
        "key": VendorType.Minor,
        "text": "Minor"
    }
];

export const documentNumberLengths = [
    {
        "location": "Olen - X755",
        "length": 34
    },
    {
        "location": "Hamburg - PN009404",
        "length": 37
    },
    {
        "location": "Test Location - XX999999",
        "length": 37
    },
    {
        "location": "General - 000",
        "length": 32
    }
];

export const docReleaseOptions = [
    'Released for Design',
    'Released for Quotation',
    'Released for Purchase',
    'Released for Fabrication',
    'Released for Construction',
    'Released for Commissioning',
    'As Built'
]

export const docPurposeOptions = [
    'Preliminary',
    'For Information',
    'For Review',
    'For Comments',
    'For Approval',
    'For Release'
]