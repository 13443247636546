import { AuthenticationContext, AdalConfig, adalFetch } from 'react-adal';

const hostname = window.location.hostname;

const clientIds: { [hostname: string]: string } = {
  "localhost": "922d726f-9672-469d-befb-0a197d9729ea",
  "adap-dev-fcm-docport.azurefd.net": "922d726f-9672-469d-befb-0a197d9729ea",
  "adap-tst-fcm-docport.azurefd.net": "922d726f-9672-469d-befb-0a197d9729ea",
  "adap-acc-fcm-docport.azurefd.net": "81754b39-6eea-42fd-b382-ffea6064436c",
  "docport-a.aurubis.com": "81754b39-6eea-42fd-b382-ffea6064436c",
  "adap-prd-fcm-docport.azurefd.net": "81754b39-6eea-42fd-b382-ffea6064436c",
  "docport.aurubis.com": "81754b39-6eea-42fd-b382-ffea6064436c",
};

export const adalConfig: AdalConfig = {
  tenant: '8b44faf2-c6e5-494d-ad12-066dfe1e51a8',
  clientId: clientIds[hostname],
  redirectUri: window.location.origin,
  endpoints: {
    api: clientIds[hostname],
    graph: 'https://graph.microsoft.com'
  },
  cacheLocation: 'localStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const apiFetch = (fetch: (input: string, init: any) => Promise<any>, url: string, options: any) =>
  adalFetch(authContext, adalConfig.endpoints!.api, fetch, url, options);