import React from 'react';
import { runWithAdal } from 'react-adal';
import ReactDOM from 'react-dom';
import { authContext } from './adalConfig';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { initializeIcons } from '@uifabric/icons';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
initializeIcons();
initializeFileTypeIcons();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

runWithAdal(authContext, () => {

    ReactDOM.render(
        <BrowserRouter basename={baseUrl!}>
            <App />
        </BrowserRouter>,
        rootElement);

}, false);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
