import { Submittal } from "../models/Submittal";
import { BlobMetadata } from "../uppy/azure-blob-storage";
import { BlobURL, Aborter } from "@azure/storage-blob";
import { ContainerListBlobFlatSegmentResponse } from "@azure/storage-blob/typings/lib/generated/lib/models";

class BlobService {
    public getFiles = async (submittal: Submittal): Promise<BlobMetadata[]> => {
        var result: BlobMetadata[] = [];
        var marker = undefined;
        do {
            var listBlobsResponse: ContainerListBlobFlatSegmentResponse =
                await submittal.containerUrl.listBlobFlatSegment(
                    Aborter.none,
                    marker,
                    {
                        include: [
                            'metadata'
                        ]
                    }
                );

            marker = listBlobsResponse.nextMarker;
            
            let files = listBlobsResponse.segment.blobItems.map(
                blob => {
                    return {
                        name: blob.name,
                        documentNumber: blob.metadata ? blob.metadata['documentnumber'] : '',
                        isDocumentNumberValid: blob.metadata && blob.metadata['isdocumentnumbervalid'] ? blob.metadata['isdocumentnumbervalid'] : 'unverified',
                        revisionNumber: blob.metadata ? blob.metadata['revisionnumber'] : '',
                        purpose: blob.metadata ? blob.metadata['purpose'] : '',
                        release: blob.metadata ? blob.metadata['release'] : ''
                    } as BlobMetadata;
                }
            )
            result.push(...files);
            
        } while (marker);

        return result;
    }

    public updateMetadata = async (submittal: Submittal, metadata: BlobMetadata): Promise<void> => {
        var containerUrl = submittal.containerUrl;
        var blobUrl = BlobURL.fromContainerURL(containerUrl, metadata.name);

        await blobUrl.setMetadata(
            Aborter.none,
            metadata
        )
    }
}

var service = new BlobService()
export default service;