import { ContextualMenu, ContextualMenuItemType, DetailsList, DetailsListLayoutMode, IColumn, IContextualMenuItem, SelectionMode, Stack } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { docPurposeOptions } from '../Metadata';
import { IScope } from '../models/Scope';
import { Submittal } from '../models/Submittal';
import apiService from '../services/ApiService';

interface ISubmittalsState {
  submittals: Submittal[];
  filteredSubmittals: Submittal[];
  columns: IColumn[];
  isCalloutVisible: boolean;
  calloutTarget: HTMLElement | undefined;
  selectedColumn: IColumn | undefined;
}

interface ISubmittalsProps extends RouteComponentProps {
  scopes: IScope[]
}

export class Submittals extends Component<ISubmittalsProps, ISubmittalsState> {
  displayName = Submittals.name

  constructor(props: ISubmittalsProps) {
    super(props);

    this.state = {
      submittals: [],
      filteredSubmittals: [],
      isCalloutVisible: false,
      calloutTarget: undefined,
      selectedColumn: undefined,
      columns: [
        {
          key: 'nameColumn',
          name: 'Submittal No.',
          fieldName: 'name',
          minWidth: 210,
          maxWidth: 350,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this.onColumnClick,
          onRender: (submittal: Submittal) => {
            return (
              <Link to={`/submittals/${submittal.name}`}>
                {submittal.name}
              </Link>
            );
          },
          isPadded: true
        },
        {
          key: 'poColumn',
          name: 'PO',
          fieldName: 'purchaseOrderId',
          minWidth: 210,
          maxWidth: 350,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this.onColumnClick,
          onRender: (submittal: Submittal) => {
            return <span>{submittal.purchaseOrderName}</span>
          },
          data: [],
          isPadded: true
        },
        {
          key: 'descriptionColumn',
          name: 'Description',
          fieldName: 'description',
          minWidth: 210,
          maxWidth: 350,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this.onColumnClick,
          isPadded: true
        },
        {
          key: 'purposeColumn',
          name: 'Purpose',
          fieldName: 'purpose',
          minWidth: 210,
          maxWidth: 350,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this.onColumnClick,
          data: docPurposeOptions.map<IContextualMenuItem>(p => {
            return {
              key: p, text: p
            }
          }),
          isPadded: true
        },
        {
          key: 'userColumn',
          name: 'User',
          fieldName: 'upn',
          minWidth: 210,
          maxWidth: 350,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this.onColumnClick,
          isPadded: true
        },
        {
          key: 'statusColumn',
          name: 'Status',
          fieldName: 'status',
          minWidth: 210,
          maxWidth: 350,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this.onColumnClick,
          data: [
            { key: 'Open', text: 'Open' },
            { key: 'Submitted', text: 'Submitted' },
            { key: 'Done', text: 'Done' }
          ],
          isPadded: true
        }
      ]
    };
  }

  async componentDidMount() {
    var submittals = await apiService.getSubmittals();
    submittals = submittals
      .sort((a, b) => a.name!.localeCompare(b.name!));
    this.setState({ submittals, filteredSubmittals: submittals });

    const purchaseOrders = submittals
      .map(s => {
        return {
          key: s.purchaseOrderId,
          text: s.purchaseOrderId ? s.purchaseOrderName : 'Blank'
        } as IContextualMenuItem
      })
      .filter((value, index, self) => {
        return self.findIndex(po => po.key === value.key) === index;
      })
      .sort((a, b) => a.text!.localeCompare(b.text!));

    const newColumns = this.state.columns.slice();
    const poColumn = newColumns.find(c => c.key === 'poColumn')!;
    poColumn.data = purchaseOrders;

    const upns = submittals
      .map(s => {
        return {
          key: s.upn,
          text: s.upn
        } as IContextualMenuItem
      })
      .filter((value, index, self) => {
        return self.findIndex(po => po.key === value.key) === index;
      })
      .sort((a, b) => a.text!.localeCompare(b.text!));

    const userColumn = newColumns.find(c => c.key === 'userColumn')!;
    userColumn.data = upns;

    this.setState({ columns: newColumns });
  }

  private onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const element = ev.target as HTMLElement;
    this.setState({
      isCalloutVisible: true,
      calloutTarget: element,
      selectedColumn: column
    });
  }

  private onCalloutDismiss = (): void => {
    this.setState({
      isCalloutVisible: false
    });
  }

  private columnSort = (column: IColumn, direction: 'ascending' | 'descending'): void => {
    const { columns, filteredSubmittals } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = direction === 'descending';
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const sortedSubmittals = copyAndSort(filteredSubmittals, currColumn.fieldName!, currColumn.isSortedDescending);

    this.setState({
      columns: newColumns,
      filteredSubmittals: sortedSubmittals
    });
  }

  private columnFilter = (column: IColumn, key: string): void => {
    const { columns, submittals } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    currColumn.data.forEach((item: IContextualMenuItem) => {
      item.isChecked = item.key === key;
    });
    currColumn.isFiltered = true;
    this.filterSubmittals(newColumns, submittals);
  }

  private columnFilterReset = (column: IColumn): void => {
    const { columns, submittals } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    currColumn.data.forEach((item: IContextualMenuItem) => {
      item.isChecked = false;
    });
    currColumn.isFiltered = false;
    this.filterSubmittals(newColumns, submittals);
  }

  private filterSubmittals = (columns: IColumn[], submittals: Submittal[]): void => {
    let filteredSubmittals: Submittal[] = submittals;
    columns.forEach(c => {
      c.data && c.data.forEach((i: IContextualMenuItem) => {
        if (i.isChecked) {
          filteredSubmittals = copyAndFilter(filteredSubmittals, c.fieldName!, i.key);
        }
      })
    });

    this.setState({
      columns,
      filteredSubmittals
    });
  }

  render() {
    const { columns, filteredSubmittals, selectedColumn } = this.state;

    let items: IContextualMenuItem[] = [];

    if (selectedColumn) {
      items = [
        {
          key: 'ascending',
          text: selectedColumn.sortAscendingAriaLabel,
          canCheck: true,
          isChecked: selectedColumn.isSorted && !selectedColumn.isSortedDescending,
          onClick: () => this.columnSort(selectedColumn, 'ascending')
        },
        {
          key: 'descending',
          text: selectedColumn.sortDescendingAriaLabel,
          canCheck: true,
          isChecked: selectedColumn.isSorted && selectedColumn.isSortedDescending,
          onClick: () => this.columnSort(selectedColumn, 'descending')
        }
      ];

      // Add a divider and filter options if they are specified in the data
      if (selectedColumn.data) {
        items.push(
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider
          },
        );

        items.push(
          ...selectedColumn.data.map((i: IContextualMenuItem) => {
            return {
              key: i.key,
              text: i.text,
              canCheck: true,
              isChecked: i.isChecked,
              onClick: () => this.columnFilter(selectedColumn, i.key)
            }
          })
        );

        if (selectedColumn.isFiltered) {
          items.push(
            {
              key: 'divider_1',
              itemType: ContextualMenuItemType.Divider
            },
            {
              key: 'resetFilter',
              text: 'Reset filter',
              onClick: () => this.columnFilterReset(selectedColumn)
            }
          );
        }
      }
    }
    return (
      <Stack tokens={{ childrenGap: 10, padding: 's2' }}>

        <DetailsList
          items={filteredSubmittals}
          columns={columns}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selectionMode={SelectionMode.none}
        />

        <ContextualMenu
          gapSpace={0}
          target={this.state.calloutTarget}
          hidden={!this.state.isCalloutVisible}
          onDismiss={this.onCalloutDismiss}
          items={items}
        />

      </Stack >
    );
  }
}

function copyAndFilter<T>(items: T[], columnKey: string, value: any): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).filter((a: T) => a[key] === value);
}

function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}