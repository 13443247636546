import { Plugin, PluginOptions, Uppy, UppyFile, IndexedObject } from '@uppy/core'
import cuid from 'cuid';
import settle from '@uppy/utils/lib/settle';
import limitPromises, { LimitedFunctionFactory } from '@uppy/utils/lib/limitPromises';

function buildResponseError(xhr: XMLHttpRequest, error: any) {
    // No error message
    if (!error) error = new Error('Upload error')
    // Got an error message string
    if (typeof error === 'string') error = new Error(error)
    // Got something else
    if (!(error instanceof Error)) {
        error = Object.assign(new Error('Upload error'), { data: error })
    }

    error.request = xhr
    return error
}

export interface BlobMetadata extends IndexedObject<any> {
    name: string,
    release: string,
    purpose: string,
    revisionNumber: string,
    documentNumber: string;
    isDocumentNumberValid: "unverified" | "yes" | "no";
}

export interface AzureBlobStorageOptions extends PluginOptions {
    storageAccountName: string;
    containerName: string;
    sas: string;
    headers: object;
    limit: number | undefined;
};

export class AzureBlobStorage extends Plugin {
    title: string;
    opts: AzureBlobStorageOptions;
    limitUploads: LimitedFunctionFactory<{}>;

    constructor(uppy: Uppy, opts: AzureBlobStorageOptions) {
        super(uppy, opts)
        this.id = opts.id || 'AzureBlobStorage';
        this.type = 'azure-blob-storage';
        this.title = 'Azure Blob Storage';

        this.handleUpload = this.handleUpload.bind(this);

        // Default options
        const defaultOptions = {
            storageAccountName: '',
            containerName: '',
            sas: '',
            headers: {
                'x-ms-blob-type': 'BlockBlob',
            },
            timeout: 30 * 1000,
            limit: 0,
            getResponseData(responseText: string, response: any) {
                let parsedResponse = {}
                try {
                    parsedResponse = JSON.parse(responseText)
                } catch (err) {
                    console.log(err)
                }

                return parsedResponse
            },
            getResponseError(responseText: string, response: any) {
                return new Error('Upload error')
            },
            validateStatus(status: number, responseText: string, response: any) {
                return status >= 200 && status < 300
            }
        }

        // Merge default options with the ones set by user
        this.opts = Object.assign({}, defaultOptions, opts)

        // Simultaneous upload limiting is shared across all uploads with this plugin.
        if (typeof this.opts.limit === 'number' && this.opts.limit !== 0) {
            this.limitUploads = limitPromises(this.opts.limit);
        } else {
            this.limitUploads = (fn) => fn;
        }
    }

    getOptions(file: UppyFile<BlobMetadata>) {
        const overrides = this.uppy.getState().xhrUpload
        const opts = Object.assign({},
            this.opts,
            overrides || {}
        )
        opts.headers = {}
        Object.assign(opts.headers, this.opts.headers)
        if (overrides) {
            Object.assign(opts.headers, overrides.headers)
        }

        return opts
    }

    // Helper to abort upload requests if there has not been any progress for `timeout` ms.
    // Create an instance using `timer = createProgressTimeout(10000, onTimeout)`
    // Call `timer.progress()` to signal that there has been progress of any kind.
    // Call `timer.done()` when the upload has completed.
    createProgressTimeout(timeout: number, timeoutHandler: (error: Error) => any) {
        const uppy = this.uppy;
        const self = this;
        let isDone = false;

        function onTimedOut() {
            uppy.log(`[${self.id}] timed out`);
            const error = new Error(`Upload stalled for ${Math.ceil(timeout / 1000)} seconds, aborting`);
            timeoutHandler(error);
        }

        let aliveTimer: NodeJS.Timeout | null = null
        function progress() {
            // Some browsers fire another progress event when the upload is
            // cancelled, so we have to ignore progress after the timer was
            // told to stop.
            if (isDone) return;

            if (timeout > 0) {
                if (aliveTimer) clearTimeout(aliveTimer);
                aliveTimer = setTimeout(onTimedOut, timeout);
            }
        }

        function done() {
            uppy.log(`[${self.id}] timer done`);
            if (aliveTimer) {
                clearTimeout(aliveTimer);
                aliveTimer = null;
            }
            isDone = true;
        }

        return {
            progress,
            done
        }
    }

    upload(file: UppyFile<BlobMetadata>, current: number, total: number) {
        const opts = this.getOptions(file)

        this.uppy.log(`uploading ${current} of ${total}`)
        return new Promise((resolve, reject) => {
            const timer = this.createProgressTimeout(opts.timeout, (error: Error) => {
                xhr.abort();
                this.uppy.emit('upload-error', file, error);
                reject(error);
            })

            const xhr = new XMLHttpRequest();

            const id = cuid();

            xhr.upload.addEventListener('loadstart', (ev) => {
                this.uppy.log(`[${this.id}] ${id} started`);
            })

            xhr.upload.addEventListener('progress', (ev) => {
                this.uppy.log(`[${this.id}] ${id} progress: ${ev.loaded} / ${ev.total}`);
                // Begin checking for timeouts when progress starts, instead of loading,
                // to avoid timing out requests on browser concurrency queue
                timer.progress();

                if (ev.lengthComputable) {
                    this.uppy.emit('upload-progress', file, {
                        uploader: this,
                        bytesUploaded: ev.loaded,
                        bytesTotal: ev.total
                    })
                }
            })

            xhr.addEventListener('load', (ev) => {
                this.uppy.log(`[${this.id}] ${id} finished`);
                timer.done();

                if (opts.validateStatus(xhr.status, xhr.responseText, xhr)) {
                    const uploadResp = {
                        status: xhr.status,
                        uploadURL: file.meta.url
                    };

                    this.uppy.emit('upload-success', file, uploadResp);

                    return resolve(file);
                } else {
                    const body = opts.getResponseData(xhr.responseText, xhr);
                    const error = buildResponseError(xhr, opts.getResponseError(xhr.responseText, xhr));

                    const response = {
                        status: xhr.status,
                        body
                    };

                    this.uppy.emit('upload-error', file, error, response);
                    return reject(error);
                }
            })

            xhr.addEventListener('error', (ev) => {
                this.uppy.log(`[${this.id}] ${id} errored`);
                timer.done();

                const error = buildResponseError(xhr, opts.getResponseError(xhr.responseText, xhr));
                this.uppy.emit('upload-error', file, error);
                return reject(error);
            })

            file.meta.url = `https://${this.opts.storageAccountName}.blob.core.windows.net/${this.opts.containerName.toLowerCase()}/${file.name}`
            xhr.open('PUT', `${file.meta.url}${this.opts.sas}`, true);

            Object.keys(opts.headers).forEach((header) => {
                xhr.setRequestHeader(header, opts.headers[header]);
            })
            
            xhr.send(file.data);

            this.uppy.on('file-removed', (removedFile) => {
                if (removedFile.id === file.id) {
                    timer.done();
                    xhr.abort();
                    reject(new Error('File removed'));
                }
            })

            this.uppy.on('cancel-all', () => {
                timer.done();
                xhr.abort();
                reject(new Error('Upload cancelled'));
            })
        })
    }

    uploadFiles(files: UppyFile<BlobMetadata>[]) {
        const actions = files.map((file, i) => {
            const current = i + 1;
            const total = files.length;

            if (file.error) {
                throw new Error(file.error);
            } else {
                this.uppy.emit('upload-started', file);
                return this.upload.bind(this, file, current, total);
            }
        })

        const promises = actions.map((action) => {
            const limitedAction = this.limitUploads(action)
            return limitedAction()
        })

        return settle(promises);
    }

    handleUpload(fileIDs: string[]) {
        this.uppy.log(`[${this.id}] Uploading...`);
        const files = fileIDs.map((fileID) => this.uppy.getFile<BlobMetadata>(fileID));

        return this.uploadFiles(files).then(() => null);
    }

    install() {
        this.uppy.addUploader(this.handleUpload);
    }

    uninstall() {
        this.uppy.removeUploader(this.handleUpload);
    }
}