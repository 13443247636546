import React, { Component } from 'react';
import { Stack } from 'office-ui-fabric-react';

export class Layout extends Component {
  displayName = Layout.name

  render() {
    return (
      <Stack horizontalAlign="center" tokens={{padding: 's2'}}>
        {this.props.children}
      </Stack>
    );
  }
}