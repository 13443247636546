import React from "react";
import { Button, Modal } from 'react-bootstrap';

export interface IConfirmationDialogProps {
    show: boolean;
    onHide: () => void;
    onSuccess: () => void;
    title: string;
    message: string;
    leftButtonText: string;
    rightButtonText: string;
}

export class ConfirmationDialog extends React.Component<IConfirmationDialogProps, any> {
    constructor(props: any, context: any) {
      super(props, context);

      this.state = {
        show: false,
      };
    }
  
    handleClose = () => {
      this.setState({...this.state, show: false });
    }
  
    handleShow = () => {
      this.setState({ ...this.state, show: true });
    }
  
    render() {
      return (
          <Modal 
          show={this.props.show} 
          onHide={this.props.onHide}>
            <Modal.Header closeButton>
              <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.props.message}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.onHide}>
                {this.props.leftButtonText}
              </Button>
              <Button variant="danger" onClick={this.props.onSuccess}>
                {this.props.rightButtonText}
              </Button>
            </Modal.Footer>
          </Modal>
      );
    }
  }