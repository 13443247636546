import React from "react";
import { Modal } from 'react-bootstrap';
import { SubmittalForm } from "./SubmittalForm";
import { Submittal } from "../models/Submittal";
import { IScope } from "../models/Scope";

export interface INewSubmittalDialogProps {
    show: boolean;
    onCancel: () => void;
    onSubmit: (submittal: Submittal) => void;
    submitlabel: any;
    scopes: IScope[];
}

export default class NewSubmittalDialog extends React.Component<INewSubmittalDialogProps> {
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onCancel}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        New Submittal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SubmittalForm
                        submitLabel={this.props.submitlabel}
                        submitId={''}
                        onCancel={this.props.onCancel}
                        onSubmit={this.props.onSubmit}
                        scopes={this.props.scopes} />
                </Modal.Body>
            </Modal>
        );
    }
}