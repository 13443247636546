import React, { Component } from 'react';
import { Route } from 'react-router';
import './App.css';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import NavMenu from './components/NavMenu';
import { SubmittalDetails } from './components/SubmittalDetails';
import { Submittals } from './components/Submittals';
import { Vendors } from './components/Vendors';
import apiService from './services/ApiService';
import { VendorDetails } from './components/VendorDetails';
import { IUser } from './models/User';

interface IAppState {
  me: IUser | undefined;
}

export class App extends Component<any, IAppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      me: undefined,
    };
  }

  async componentDidMount() {
    let me = await apiService.me();
    this.setState({ ...this.state, me });
  }

  render() {
    const { me } = this.state;

    return (
      <>
        <NavMenu me={me} />
        <Layout>
          <Route exact path='/' render={(routeProps) => <Home {...routeProps} scopes={me ? me.scopes : []} />} />
          <Route path='/submittals/:submittalId' render={(routeProps) => <SubmittalDetails {...routeProps} scopes={me ? me.scopes : []} />} />
          <Route exact path='/submittals' component={Submittals} />
          <Route path='/vendors/:vendorId' component={VendorDetails} />
          <Route exact path='/vendors' component={Vendors} />
        </Layout>
      </>
    );
  }
}

export default App;
