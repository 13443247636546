export enum VendorType {
    Unknown,
    Major,
    Minor
}

export interface IVendor {
    id: string;
    code: string;
    name: string;
    type: VendorType;
    location: string;
}