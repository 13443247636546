import { Spinner } from "office-ui-fabric-react";
import React from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { docPurposeOptions, docReleaseOptions } from "../Metadata";
import { DisplayMode } from "../models/DisplayMode";
import { IPurchaseOrder } from "../models/PurchaseOrder";
import { IScope } from "../models/Scope";
import { Submittal } from "../models/Submittal";
import { VendorType } from "../models/Vendor";
import apiService from "../services/ApiService";

export interface ISubmittalFormProps {
    submitLabel: string;
    submitId: string;
    onSubmit?: (submittal: Submittal) => void;
    onCancel?: () => void;
    displayMode?: DisplayMode;
    scopes: IScope[];
}

interface ISubmittalFormState {
    isValidated: boolean;
    purchaseOrders: IPurchaseOrder[];
    submittal: Submittal;
    displayMode: DisplayMode;
}

export class SubmittalForm extends React.Component<ISubmittalFormProps, ISubmittalFormState> {
    constructor(props: ISubmittalFormProps) {
        super(props);

        this.state = {
            isValidated: false,
            purchaseOrders: [],
            submittal: new Submittal(),
            displayMode: props.displayMode
                ? props.displayMode
                : props.submitId ? DisplayMode.ReadOnly : DisplayMode.New
        };
    }

    async componentDidMount() {
        await this.onScopesChanged(this.props.scopes);

        if (this.props.submitId !== '') {
            let submittal = await apiService.getSubmittal(this.props.submitId)
            this.setState({
                ...this.state,
                submittal,
                displayMode: submittal.status === "Open" ? DisplayMode.Edit : DisplayMode.ReadOnly
            });
        }
    }

    async componentWillReceiveProps(props: ISubmittalFormProps) {
        if (props.displayMode && this.props.displayMode !== props.displayMode) {
            this.setState({ ...this.state, displayMode: props.displayMode });
        }

        if (this.props.scopes !== props.scopes) {
            await this.onScopesChanged(props.scopes);
        }
    }

    private async onScopesChanged(scopes: IScope[]) {
        // If there's just one scope, we select it as default
        if (scopes.length === 1) {
            await this.setScope(scopes[0]);
        }
    }

    submit = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity()) {
            let submittal = this.state.displayMode === DisplayMode.Edit
                ? await apiService.updateSubmittal(this.state.submittal)
                : await apiService.createSubmittal(this.state.submittal);

            this.setState({ ...this.state, submittal, isValidated: true });
            this.props.onSubmit && this.props.onSubmit(submittal);
        }
    }

    handleInputChange = async (event: any) => {
        // Create a copy of submittal
        let submittal = new Submittal(this.state.submittal);


        switch (event.target.name) {
            case 'selectedPurchaseOrder':
                var purchaseOrder = this.state.purchaseOrders.find(po => po.id === event.target.value);
                if (purchaseOrder) {
                    submittal.purchaseOrderId = purchaseOrder.id;
                    submittal.purchaseOrderName = purchaseOrder.name;
                }
                break;
            case 'selectedRelease':
                submittal.release = event.target.value;
                break;
            case 'selectedPurpose':
                submittal.purpose = event.target.value;
                break;
            case 'typedDescription':
                submittal.description = event.target.value;
                break;
        }

        const form = event.currentTarget;
        if (submittal.accessToken && form.checkValidity()) {
            await apiService.updateSubmittal(submittal);
        }

        this.setState({ ...this.state, submittal });
    }

    handleScopeChange = async (event: any) => {
        let scopeId = event.target.value;

        // Create a copy of submittal
        let submittal = new Submittal(this.state.submittal);
        submittal.scopeId = scopeId;

        var purchaseOrders = await apiService.getPurchaseOrders(scopeId);

        const form = event.currentTarget;
        if (submittal.accessToken && form.checkValidity()) {
            await apiService.updateSubmittal(submittal);
        }

        this.setState({ ...this.state, submittal, purchaseOrders });
    }

    setScope = async (scope: IScope) => {
        // Create a copy of submittal
        let submittal = new Submittal(this.state.submittal);
        submittal.scopeId = scope.id;
        this.setState({ ...this.state, submittal });

        let vendor = await apiService.getVendor(scope.vendorId);
        if (vendor.type === VendorType.Major) {
            let purchaseOrders = await apiService.getPurchaseOrders(scope.id);
            this.setState({ ...this.state, purchaseOrders });
        }
    }

    render() {
        var {
            isValidated,
            submittal,
            displayMode,
            purchaseOrders
        } = this.state;

        var {
            submitId,
            submitLabel,
            onCancel,
            scopes
        } = this.props;

        purchaseOrders = purchaseOrders.sort((a, b) => a.name.localeCompare(b));

        return (
            <Form noValidate validated={isValidated} onSubmit={(e: any) => this.submit(e)}>
                {
                    !submitId && // Only show this selection for new submittals
                    <Form.Row>
                        {
                            scopes.length > 1 &&
                            <Form.Group as={Col} controlId="formScope">
                                <Form.Label>Project</Form.Label>
                                <Form.Control as="select"
                                    required
                                    name="selectedScope"
                                    value={submittal.scopeId}
                                    onChange={this.handleScopeChange}
                                    disabled={displayMode === DisplayMode.ReadOnly}>
                                    <option></option>
                                    {scopes.map(s =>
                                        (
                                            <option key={s.id} value={s.id}>{s.name}</option>
                                        )
                                    )}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please select a Purchase Order Number
                                </Form.Control.Feedback>
                            </Form.Group>
                        }

                        {
                            purchaseOrders.length > 0 && // Only major vendors have purchase orders
                            <Form.Group as={Col} controlId="formPurchaseOrder">
                                <Form.Label>Purchase Order Number</Form.Label>
                                <div style={{ position: 'relative' }}>
                                    <Form.Control as="select"
                                        required
                                        name="selectedPurchaseOrder"
                                        value={submittal.purchaseOrderId}
                                        onChange={this.handleInputChange}
                                        disabled={!submittal.scopeId || displayMode === DisplayMode.ReadOnly}>
                                        <option></option>
                                        {purchaseOrders.map(po =>
                                            (
                                                <option key={po.id} value={po.id}>{po.name}</option>
                                            )
                                        )}
                                    </Form.Control>
                                    {
                                        submittal.purchaseOrderId && purchaseOrders.length === 0 &&
                                        <Spinner style={{ position: 'absolute', top: '9px', left: '9px' }} />
                                    }
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    Please select a Purchase Order Number
                            </Form.Control.Feedback>
                            </Form.Group>
                        }
                    </Form.Row>
                }

                <Form.Row>
                    <Form.Group as={Col} controlId="formRelease">
                        <Form.Label>Doc Release</Form.Label>
                        <Form.Control as="select" required
                            name="selectedRelease"
                            value={submittal.release}
                            onChange={this.handleInputChange}
                            disabled={displayMode === DisplayMode.ReadOnly}>
                            <option></option>
                            {docReleaseOptions.map(o => <option key={o}>{o}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please select a Doc Release
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formPurpose">
                        <Form.Label>Doc Purpose</Form.Label>
                        <Form.Control as="select" required
                            name="selectedPurpose"
                            value={submittal.purpose}
                            onChange={this.handleInputChange}
                            disabled={displayMode === DisplayMode.ReadOnly}>
                            <option></option>
                            {docPurposeOptions.map(o => <option key={o}>{o}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please select a Doc Purpose
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="3"
                            name="typedDescription"
                            value={submittal.description}
                            onChange={this.handleInputChange}
                            disabled={displayMode === DisplayMode.ReadOnly} />
                    </Form.Group>
                </Form.Row>

                {displayMode === DisplayMode.New &&
                    <Form.Group as={Row}>
                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type='submit' style={{ marginRight: '.25em' }}>{submitLabel}</Button>
                            <Button variant="secondary" style={{ marginLeft: '.25em' }} onClick={onCancel}>Cancel</Button>
                        </Col>
                    </Form.Group>
                }
            </Form>
        );
    }
}