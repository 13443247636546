import { AnonymousCredential, ContainerURL, StorageURL } from "@azure/storage-blob";
import { apiConfig } from "../apiConfig";

export class Submittal {
    name: string;
    vendorId: string;
    upn: string;
    description: string;
    purchaseOrderId: string | undefined;
    purchaseOrderName: string | undefined;
    release: string;
    purpose: string;
    status: "Open" | "Submitted" | "Done";
    accessToken: string | undefined;
    scopeId: string;

    public constructor(data?: any){
        this.name = ''
        this.vendorId = '';
        this.upn = '';
        this.description = '';
        this.release = '';
        this.purpose = '';
        this.status = "Open";
        this.scopeId = '';

        if (data) {
            Object.assign(this, data);
        }
    }

    get containerUrl(): ContainerURL {
        return new ContainerURL(
            `https://${apiConfig.storageAccountName}.blob.core.windows.net/${this.name!.toLowerCase()}${this.accessToken}`,
            StorageURL.newPipeline(new AnonymousCredential())
        );
    }
}