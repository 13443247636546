import { CommandBar, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, Stack } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IScope } from '../models/Scope';
import { Submittal } from '../models/Submittal';
import apiService from '../services/ApiService';
import NewSubmittalDialog from './NewSubmittalDialog';

interface IHomeState {
  isNewSubmittalDialogVisible: boolean;
  submitLabel: any;
  submittals: any;
}

interface IHomeProps extends RouteComponentProps {
  scopes: IScope[]
}

export class Home extends Component<IHomeProps, IHomeState> {
  displayName = Home.name

  constructor(props: IHomeProps) {
    super(props);

    this.state = {
      isNewSubmittalDialogVisible: false,
      submittals: [],
      submitLabel: 'Create'
    };
  }

  showNewSubmittalDialog = () => {
    this.setState({ ...this.state, isNewSubmittalDialogVisible: true });
  }

  onNewSubmittalDialogCancel = () => {
    this.setState({ ...this.state, isNewSubmittalDialogVisible: false });
  }

  onNewSubmittalDialogSubmit = (submittal: Submittal) => {
    this.props.history.push(`/submittals/${submittal.name}`);
  }

  async componentDidMount() {
    var submittals = await apiService.getSubmittals();
    submittals = submittals
      .filter(s => s.status === "Open" || s.status === "Submitted")
      .sort((a, b) => a.name!.localeCompare(b.name!));
    this.setState({ ...this.state, submittals });
  }

  render() {
    const { submittals } = this.state;

    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'Submittal No.',
        fieldName: 'name',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        onRender: (submittal: Submittal) => {
          return (
            <Link to={`/submittals/${submittal.name}`}>
              {submittal.name}
            </Link>
          );
        },
        data: 'string',
        isPadded: true
      },
      {
        key: 'column2',
        name: 'PO',
        fieldName: 'purchaseOrderName',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true
      },
      {
        key: 'column3',
        name: 'Description',
        fieldName: 'description',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true
      },
      {
        key: 'column4',
        name: 'Purpose',
        fieldName: 'purpose',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true
      },
      {
        key: 'column5',
        name: 'User',
        fieldName: 'upn',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true
      },
      {
        key: 'column6',
        name: 'Status',
        fieldName: 'status',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true
      }
    ];

    return (
      <Stack tokens={{ childrenGap: 10, padding: 's2' }}>
        <CommandBar
            items={[
              {
                key: 'newSubmittal',
                name: 'New submittal',
                iconProps: {
                  iconName: 'FabricNewFolder'
                },
                onClick: this.showNewSubmittalDialog,
              }
            ]}
          />
          
        <NewSubmittalDialog
          show={this.state.isNewSubmittalDialogVisible}
          submitlabel={this.state.submitLabel}
          onCancel={this.onNewSubmittalDialogCancel}
          onSubmit={this.onNewSubmittalDialogSubmit}
          scopes={this.props.scopes} />

        <DetailsList
          items={submittals}
          columns={columns}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selectionMode={SelectionMode.none}
        />
      </Stack>
    );
  }
}