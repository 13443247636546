import { CommandBar, DetailsList, DetailsListLayoutMode, IColumn, IconButton, mergeStyleSets, MessageBar, MessageBarType, SelectionMode, Spinner, SpinnerSize, Stack, TextField } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { Card, Form } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import * as Rx from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { documentNumberLengths, vendorTypes } from '../Metadata';
import { IInvitation } from '../models/Invitation';
import { IScope } from '../models/Scope';
import { IVendor, VendorType } from '../models/Vendor';
import apiService from '../services/ApiService';
import { ConfirmationDialog } from './ConfirmationDialog';
import NewInvitationDialog from './NewInvitationDialog';

interface IVendorDetailsState {
  vendor: IVendor | undefined;
  invitations: IInvitation[];
  scopes: IScope[];
  selectedInvitation: IInvitation | null;
  showNewInvitationModal: boolean;
  showDeleteInvitationModal: boolean;
}

interface IVendorDetailsProps extends RouteComponentProps<{ vendorId: string }> {
}

export class VendorDetails extends Component<IVendorDetailsProps, IVendorDetailsState> {

  private scopeChanged$: Rx.Subject<IScope>;

  constructor(props: IVendorDetailsProps) {
    super(props);

    this.scopeChanged$ = new Rx.Subject<IScope>();
    this.scopeChanged$
      .asObservable()
      .pipe(
        debounceTime(500)
      )
      .subscribe({
        next: async (scope) => {
          await apiService.updateScope(scope);
        }
      });

    this.state = {
      vendor: undefined,
      invitations: [],
      scopes: [],
      selectedInvitation: null,
      showNewInvitationModal: false,
      showDeleteInvitationModal: false
    };
  }

  async componentDidMount() {
    let { vendorId } = this.props.match.params;

    let vendor = await apiService.getVendor(vendorId);
    this.setState({ vendor });

    let invitations = await this.getInvitations(vendor);
    this.setState({ invitations });

    let scopes = await this.getScopes(vendor);
    this.setState({ scopes });
  }

  onTypeChange = async (event: any) => {
    if (this.state.vendor) {
      let vendor = { ...this.state.vendor };
      vendor.type = parseInt(event.target.value);

      await apiService.updateVendor(vendor);
      let scopes = await this.getScopes(vendor);
      this.setState({
        vendor,
        scopes
      });
    }
  }

  onLocationChange = async (event: any) => {
    if (this.state.vendor) {
      let vendor = { ...this.state.vendor };
      vendor.location = event.target.value;
      await apiService.updateVendor(vendor);
      this.setState({ vendor });
    }
  }

  getInvitations = async (vendor: IVendor) => {
    const invitations = await apiService.getInvitations(vendor.id);
    return invitations.sort((a, b) => a.name.localeCompare(b.name));
  }

  getScopes = async (vendor: IVendor) => {
    const scopes = await apiService.getScopes(vendor.id);
    return scopes.sort((a, b) => a.name.localeCompare(b.name));
  }

  inviteUser = async () => {
    this.setState({ showNewInvitationModal: true });
  }

  onNewInvitationCancelled = async () => {
    this.setState({ showNewInvitationModal: false });
  }

  onNewInvitationSubmitted = async (invitation: IInvitation) => {
    let { vendor } = this.state;

    if (vendor) {
      let invitations = [...this.state.invitations, invitation];
      invitations = invitations.sort((a, b) => a.name.localeCompare(b.name));
      this.setState({
        invitations,
        showNewInvitationModal: false,
      });
    }
  }

  deleteUser = (invitation: IInvitation) => {
    this.setState({
      selectedInvitation: invitation,
      showDeleteInvitationModal: true,
    });
  }

  onDeleteUserCancelled = async () => {
    this.setState({
      selectedInvitation: null,
      showDeleteInvitationModal: false,
    });
  }

  onDeleteUserSuccess = async (invitation: IInvitation) => {
    await apiService.deleteInvitation(invitation);

    let invitations = this.state.invitations.filter(i => i.id !== invitation.id)
    this.setState({
      invitations,
      selectedInvitation: null,
      showDeleteInvitationModal: false,
    });
  }

  updateDocumentLibraryAddress = async (source: IScope, value: any): Promise<void> => {
    let scopes = this.state.scopes.map(s =>
      s.id === source.id
        ? { ...s }
        : s
    );

    let scope = scopes.find(s => s.name === source.name);

    if (scope) {
      scope.documentLibraryAddress = value;
      this.scopeChanged$.next(scope);
      this.setState({ scopes });
    }
  }

  render() {
    const {
      vendor,
      invitations
    } = this.state;

    const styles = mergeStyleSets({
      root: {
        width: 1294
      }
    });

    return (
      <Stack tokens={{ childrenGap: 10, padding: 's2' }}
        className={styles.root}>
        {
          vendor && vendor.type === VendorType.Unknown &&
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
          >
            The type of this vendor is unknown. Change the type to Major or Minor in
            order to invite users.
          </MessageBar>
        }

        {
          vendor && vendor.location.length === 0 &&
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
          >
            The location of this vendor is unknown. Set the location in
            order to invite users.
          </MessageBar>
        }

        <Card>
          <Card.Header>
            {vendor && vendor.code}
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group controlId="formCode">
                <Form.Label>Code</Form.Label>
                <Form.Text>
                  {vendor && vendor.code}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Text>
                  {vendor && vendor.name}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formType">
                <Form.Label>Type</Form.Label>
                {
                  (
                    invitations && invitations.length === 0 &&
                    <Form.Control as="select"
                      value={vendor && vendor.type.toString()}
                      onChange={this.onTypeChange}>
                      {vendorTypes.map((t) =>
                        <option key={t.key} value={t.key}>{t.text}</option>
                      )}
                    </Form.Control>
                  ) ||
                  <Form.Text>{vendor && vendorTypes.filter(t => t.key === vendor.type)[0].text}</Form.Text>
                }
              </Form.Group>

              <Form.Group controlId="formLocation">
                <Form.Label>Location</Form.Label>
                {
                  (
                    invitations && invitations.length === 0 &&
                    <Form.Control as="select"
                      value={vendor && vendor.location}
                      onChange={this.onLocationChange}>
                      <option value="">Unknown</option>
                      {documentNumberLengths.map((l, i) =>
                        <option key={i} value={l.location}>{l.location}</option>
                      )}
                    </Form.Control>
                  ) ||
                  <Form.Text>{vendor && vendor.location}</Form.Text>
                }
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>

        {
          vendor && vendor.type !== VendorType.Unknown &&
          this.renderInvitations()
        }

        {
          vendor && vendor.type === VendorType.Major &&
          this.renderScopes()
        }
      </Stack >
    );
  }

  renderInvitations() {
    let {
      vendor,
      scopes,
      invitations,
      selectedInvitation,
      showNewInvitationModal,
      showDeleteInvitationModal
    } = this.state;

    const columns: IColumn[] = [
      {
        key: 'nameColumn',
        name: 'Name',
        fieldName: 'name',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true
      },
      {
        key: 'emailAdressColumn',
        name: 'E-mail Address',
        fieldName: 'emailAddress',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true
      },
      {
        key: 'scopeColumn',
        name: 'Project',
        fieldName: 'scopeId',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (invitation: IInvitation) => {
          if (scopes.length > 0) {
            const scope = scopes.find(s => s.id === invitation.scopeId);
            return <span>{scope && scope.name}</span>
          }
          else {
            return <Spinner size={SpinnerSize.small} style={{ float: 'left' }} />
          }
        }
      },
      {
        key: 'deleteColumn',
        name: ' ',
        fieldName: 'name',
        minWidth: 16,
        maxWidth: 34,
        isResizable: false,
        isCollapsible: false,
        data: 'string',
        onRender: (invitation: IInvitation) => {
          return <IconButton
            iconProps={{ iconName: 'Delete' }}
            title="Delete"
            ariaLabel="Delete"
            onClick={() => this.deleteUser(invitation)}
          />
        }
      }
    ];

    return <>
      <CommandBar
        items={[
          {
            key: 'invite',
            name: 'Invite user',
            iconProps: {
              iconName: 'Add'
            },
            onClick: () => { this.inviteUser() },
            disabled: !vendor || // Inviting users is disabled if vendor isn't loaded
              vendor.type === VendorType.Unknown || // Inviting user is disabled if vendor type is unknown
              !vendor.location || // Inviting users is disabled if vendor location is unknown
              !scopes // Inviting users is disabled if scopes aren't loaded
          }
        ]}
      />

      {
        vendor &&
        <NewInvitationDialog
          show={showNewInvitationModal}
          vendor={vendor}
          scopes={scopes}
          onCancel={() => this.onNewInvitationCancelled()}
          onSubmit={(invitation) => this.onNewInvitationSubmitted(invitation)}
        />
      }

      {
        selectedInvitation &&
        <ConfirmationDialog
          show={showDeleteInvitationModal}
          onHide={() => this.onDeleteUserCancelled()}
          onSuccess={() => this.onDeleteUserSuccess(selectedInvitation!)}
          title="Delete invitation"
          message={`Are you sure you want to delete the invitation for ${selectedInvitation!.name}.`}
          leftButtonText="Cancel"
          rightButtonText="Delete"
        />
      }
      <Card>
        <Card.Header>
          Invitations
            </Card.Header>
        <Card.Body>
          <DetailsList
            items={invitations}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            selectionMode={SelectionMode.none}
          />
        </Card.Body>
      </Card>
    </>
  }

  renderScopes() {
    let {
      scopes
    } = this.state;

    const columns: IColumn[] = [
      {
        key: 'nameColumn',
        name: 'Name',
        fieldName: 'name',
        minWidth: 110,
        maxWidth: 210,
        isResizable: true,
        data: 'string',
        isPadded: true
      },
      {
        key: 'pathColumn',
        name: 'Path',
        fieldName: 'path',
        minWidth: 110,
        maxWidth: 210,
        isResizable: true,
        data: 'string',
        isPadded: true
      },
      {
        key: 'documentLibraryAddressColumn',
        name: 'Project URL',
        fieldName: 'documentLibraryAddress',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (scope: IScope) => {
          return (
            <TextField
              value={scope.documentLibraryAddress ? scope.documentLibraryAddress : ''}
              onChange={(event, newValue) => this.updateDocumentLibraryAddress(scope, newValue)}
            />
          );
        }
      }
    ];

    return (
      <Card>
        <Card.Header>
          Projects
        </Card.Header>
        <Card.Body>
          <DetailsList
            items={scopes}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            selectionMode={SelectionMode.none}
          />
        </Card.Body>
      </Card>
    );
  }
}