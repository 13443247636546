var hostname = window.location.hostname;

const storageAccountNames: { [hostname: string]: string } = {
  "localhost": "adapdevfcmdocport",
  "adap-dev-fcm-docport.azurefd.net": "adapdevfcmdocport",
  "adap-tst-fcm-docport.azurefd.net": "adaptstfcmdocport",
  "adap-acc-fcm-docport.azurefd.net": "adapaccfcmdocport",
  "docport-a.aurubis.com": "adapaccfcmdocport",
  "adap-prd-fcm-docport.azurefd.net": "adapprdfcmdocport",
  "docport.aurubis.com": "adapprdfcmdocport",
};

const urls: { [hostname: string]: string } = {
  "localhost": "http://localhost:7071/api",
  "adap-dev-fcm-docport.azurefd.net": "https://adap-dev-fcm-docport.azurefd.net/api",
  "adap-tst-fcm-docport.azurefd.net": "https://adap-tst-fcm-docport.azurefd.net/api",
  "adap-acc-fcm-docport.azurefd.net": "https://adap-acc-fcm-docport.azurefd.net/api",
  "docport-a.aurubis.com": "https://docport-a.aurubis.com/api",
  "adap-prd-fcm-docport.azurefd.net": "https://adap-prd-fcm-docport.azurefd.net/api",
  "docport.aurubis.com": "https://docport.aurubis.com/api",
};

export const apiConfig = {
  storageAccountName: storageAccountNames[hostname],
  url: urls[hostname]
};