import React, { Component } from 'react';
import { IColumn, DetailsList, DetailsListLayoutMode, SelectionMode } from 'office-ui-fabric-react';
import { IVendor } from '../models/Vendor';
import apiService from '../services/ApiService';
import { Link } from 'react-router-dom';

interface IVendorsState {
  vendors: IVendor[];
}

export class Vendors extends Component<any, IVendorsState> {

  constructor(props: any) {
    super(props);

    this.state = {
      vendors: []
    };
  }

  async componentDidMount() {
    var vendors = await apiService.getVendors();
    this.setState({ ...this.state, vendors });
  }

  render() {
    const { vendors } = this.state;

    const columns: IColumn[] = [
      {
        key: 'codeColumn',
        name: 'Code',
        fieldName: 'code',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        onRender: (vendor: IVendor) => {
          return (
            <Link to={`/vendors/${vendor.id}`}>
              {vendor.code}
            </Link>
          );
        },
        data: 'string',
        isPadded: true
      },
      {
        key: 'nameColumn',
        name: 'Name',
        fieldName: 'name',
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        data: 'string',
        isPadded: true
      }
    ];

    return (
      <DetailsList
        items={vendors}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        selectionMode={SelectionMode.none}
      />
    );
  }
}