import { apiFetch } from "../adalConfig";
import { apiConfig } from "../apiConfig";
import { Submittal } from "../models/Submittal";
import { IScope } from "../models/Scope";
import { IPurchaseOrder } from "../models/PurchaseOrder";
import { IVendor } from "../models/Vendor";
import { IInvitation } from "../models/Invitation";
import { IUser } from "../models/User";

class ApiService {
    private _me: IUser | undefined;

    public async me(): Promise<IUser> {
        if (!this._me) {
            var url = `${apiConfig.url}/me`
            var response = await apiFetch(fetch, url, '');
            this._me = await response.json() as IUser;
        }

        return this._me;
    }

    public async getVendor(vendorId: string): Promise<IVendor> {
        var url = `${apiConfig.url}/vendors/${vendorId}`;
        var response = await apiFetch(fetch, url, '');
        return await response.json();
    }

    async getVendors(): Promise<IVendor[]> {
        var url = `${apiConfig.url}/vendors`;
        var response = await apiFetch(fetch, url, '');
        return await response.json();
    }

    async updateVendor(vendor: IVendor): Promise<IVendor> {
        var options = {
            method: 'put',
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(vendor)
        };

        var url = `${apiConfig.url}/vendors/${vendor.id}`
        var response = await apiFetch(fetch, url, options);
        return await response.json();
    }

    async getInvitations(vendorId: string): Promise<IInvitation[]> {
        var url = `${apiConfig.url}/vendors/${vendorId}/invitations`
        var response = await apiFetch(fetch, url, '');
        return await response.json();
    }

    async getScopes(vendorId: string): Promise<IScope[]> {
        var url = `${apiConfig.url}/vendors/${vendorId}/scopes`
        var response = await apiFetch(fetch, url, '');
        return await response.json();
    }

    async updateScope(scope: IScope): Promise<IScope> {
        var options = {
            method: 'put',
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(scope)
        };

        var url = `${apiConfig.url}/vendors/${scope.vendorId}/scopes`
        var response = await apiFetch(fetch, url, options);
        return await response.json();
    }

    async getPurchaseOrders(scopeId: string): Promise<IPurchaseOrder[]> {
        var url = `${apiConfig.url}/scopes/${scopeId}/purchaseOrders`;
        var response = await apiFetch(fetch, url, '');
        return await response.json();
    }

    async getSubmittals(): Promise<Submittal[]> {
        var me = await this.me();
        var url = `${apiConfig.url}/vendors/${me.vendorId}/submittals`;
        var response = await apiFetch(fetch, url, '');
        return await response.json();
    }

    async getSubmittal(submittalId: string): Promise<Submittal> {
        var me = await this.me();
        var url = `${apiConfig.url}/vendors/${me.vendorId}/submittals/${submittalId}`;
        var response = await apiFetch(fetch, url, '');
        var responseJson = await response.json();
        return new Submittal(responseJson);
    }

    async updateSubmittal(submittal: Submittal): Promise<Submittal> {
        var options = {
            method: 'put',
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(
                submittal
            )
        };

        let result = await this.upsertSubmittal(options);
        Object.assign(submittal, result);
        return submittal;
    }

    async createSubmittal(submittal: Submittal): Promise<Submittal> {
        var options = {
            method: 'post',
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(
                submittal
            )
        };
        let result = await this.upsertSubmittal(options);
        Object.assign(submittal, result);
        return submittal;
    }

    async deleteSubmittal(submittalId: string) {
        var me = await this.me();
        var url = `${apiConfig.url}/vendors/${me.vendorId}/submittals/${submittalId}`;
        await apiFetch(fetch, url, { method: 'delete' });
    }

    async submitSubmittal(submittalId: string): Promise<Submittal> {
        var me = await this.me();
        var url = `${apiConfig.url}/vendors/${me.vendorId}/submittals/${submittalId}/submit`;
        let result = await apiFetch(fetch, url, { method: 'post' });
        let json = await result.json();
        return new Submittal(json);
    }

    private async upsertSubmittal(options: any): Promise<Submittal> {
        var me = await this.me();
        var url = `${apiConfig.url}/vendors/${me.vendorId}/submittals`;
        var response = await apiFetch(fetch, url, options);
        var json = await response.json();
        return json;
    }

    async getDocumentNumber(documentNumber: string): Promise<boolean> {
        var url = `${apiConfig.url}/documentNumbers/${documentNumber}`;
        var response = await apiFetch(fetch, url, '');
        console.info(response);
        return response.ok;
    }

    async deleteDocument(submittal: any, documentName: string) {
        var me = await this.me();
        var url = `${apiConfig.url}/vendors/${me.vendorId}/submittals/${submittal.name}/documents/${documentName}`;
        await apiFetch(fetch, url,
            {
                method: 'delete',
                body: JSON.stringify(
                    submittal
                )
            });
    }

    async inviteUser(invitation: IInvitation) {
        var url = `${apiConfig.url}/vendors/${invitation.vendorId}/invitations`;
        await apiFetch(fetch, url,
            {
                method: 'post',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(
                    invitation
                )
            }
        );
    }

    async deleteInvitation(invitation: IInvitation) {
        var url = `${apiConfig.url}/vendors/${invitation.vendorId}/invitations/${invitation.id}`;
        await apiFetch(fetch, url,
            {
                method: 'delete'
            });
    }
}

const apiService = new ApiService();
export default apiService;