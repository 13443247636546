import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { Uppy, UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { DashboardModal } from '@uppy/react';
import { ComboBox, CommandBar, Icon, IconButton, mergeStyleSets, Spinner, SpinnerSize, Stack, TextField } from 'office-ui-fabric-react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import React, { Component } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { apiConfig } from '../apiConfig';
import { docPurposeOptions, docReleaseOptions, documentNumberLengths } from '../Metadata';
import { DisplayMode } from '../models/DisplayMode';
import { IScope } from '../models/Scope';
import { Submittal } from '../models/Submittal';
import apiService from '../services/ApiService';
import blobService from '../services/BlobService';
import { AzureBlobStorage, BlobMetadata } from '../uppy/azure-blob-storage';
import { ConfirmationDialog } from './ConfirmationDialog';
import { SubmittalForm } from './SubmittalForm';
import { IVendor } from '../models/Vendor';

interface ISubmittalState {
  files: BlobMetadata[];
  submittalId: string;
  submittal: Submittal | null;
  vendor: IVendor | null
  uppyModalOpen: boolean;
  showConfirmationDialog: boolean;
  confirmationDialogTitleText: string;
  confirmationDialogMessageText: string;
  confirmationDialogRightButtonText: string;
  confirmationDialogSuccessAction: any;
  fileToDelete: string;
}

interface ISubmittalDetailsProps extends RouteComponentProps<{ submittalId: string }> {
  scopes: IScope[]
}

export class SubmittalDetails extends Component<ISubmittalDetailsProps, ISubmittalState> {
  displayName = Submittal.name
  uppy: Uppy;

  constructor(props: ISubmittalDetailsProps) {
    super(props);

    this.state = {
      files: [],
      submittalId: props.match.params.submittalId,
      submittal: null,
      vendor: null,
      uppyModalOpen: false,
      showConfirmationDialog: false,
      confirmationDialogTitleText: '',
      confirmationDialogMessageText: '',
      confirmationDialogRightButtonText: '',
      confirmationDialogSuccessAction: this.onSuccessDialogForDocument,
      fileToDelete: ''
    };

    this.uppy = new Uppy({ id: 'uppy1', autoProceed: true });
  }

  async componentDidMount() {
    var submittal = await apiService.getSubmittal(this.state.submittalId);
    this.setState({ submittal });

    var vendor = await apiService.getVendor(submittal.vendorId);
    this.setState({ vendor });

    let files = await blobService.getFiles(submittal);

    files = [...this.state.files, ...files];
    this.setState({ files })

    this.uppy.use(AzureBlobStorage, {
      storageAccountName: apiConfig.storageAccountName,
      containerName: submittal.name,
      sas: submittal.accessToken
    });

    this.uppy.on('upload-success', async (file: UppyFile<BlobMetadata>, response) => {
      var metadata: BlobMetadata = {
        name: file.name,
        revisionNumber: '1',
        release: submittal.release || '',
        purpose: submittal.purpose || '',
        documentNumber: this.extractDocumentNumber(file.name),
        isDocumentNumberValid: "unverified"
      };

      await blobService.updateMetadata(submittal, metadata);

      // Remove if duplicate file
      var files = this.state.files.filter(e => e.name !== file.name);

      // Add new file
      files = [...files, metadata];
      this.setState({ files });

      this.verifyDocumentNumber(submittal, metadata);
    });

    this.uppy.on('complete', (result) => {
      console.log('Upload complete! We’ve uploaded these files:', result.successful);
    });

    // Verify any document numbers that haven't been verified before
    let filesWithUnverifiedDocumentNumbers = files.filter(f => !f.isDocumentNumberValid || f.isDocumentNumberValid === "unverified");
    await this.verifyDocumentNumbers(submittal, filesWithUnverifiedDocumentNumbers);
  }

  handleDeleteDocument = async (documentName: any) => {
    await apiService.deleteDocument(this.state.submittal, documentName);
    var files = this.state.files.filter(e => e.name !== documentName);
    this.setState({ ...this.state, files })
  }

  removeDocument = async (documentName: any) => {
    if (this.state.files.some(e => e.name === documentName)) {
      var files = this.state.files.filter(e => e.name !== documentName);
      this.setState({ ...this.state, files })
    }
  }

  uppyOnRequestClose = () => {
    this.setState({ ...this.state, uppyModalOpen: false })
  }

  uppyOnRequestOpen = () => {
    this.setState({ ...this.state, uppyModalOpen: true })
  }

  onDeleteSubmittal = async () => {
    this.setState({ ...this.state, showConfirmationDialog: false })
    await apiService.deleteSubmittal(this.state.submittal!.name as string);
    this.props.history.push(`/`);
  }

  showDeleteDialogForSubmittal = () => {
    this.setState({
      ...this.state,
      showConfirmationDialog: true,
      confirmationDialogTitleText: 'Delete this submittal?',
      confirmationDialogMessageText: 'Are you sure you want to delete this submittal?',
      confirmationDialogRightButtonText: 'Delete',
      confirmationDialogSuccessAction: this.onDeleteSubmittal,
    })
  }

  onSuccessDialogForDocument = () => {
    this.handleDeleteDocument(this.state.fileToDelete);
    this.setState({
      ...this.state,
      showConfirmationDialog: false,
      fileToDelete: ''
    });
  }

  showDeleteDialogForDocument = (fileName: string) => {
    this.setState({
      ...this.state,
      showConfirmationDialog: true,
      confirmationDialogTitleText: 'Delete this document?',
      confirmationDialogMessageText: `Are you sure you want to delete ${fileName}?`,
      confirmationDialogRightButtonText: 'Delete',
      confirmationDialogSuccessAction: this.onSuccessDialogForDocument,
      fileToDelete: fileName
    })
  }

  onHideDialog = () => {
    this.setState({
      ...this.state,
      showConfirmationDialog: false,
      fileToDelete: ''
    })
  }

  submitSubmittal = () => {
    if (this.state.submittal && this.state.submittal.name) {
      this.setState({
        ...this.state,
        showConfirmationDialog: true,
        confirmationDialogTitleText: `Submit ${this.state.submittal.name}?`,
        confirmationDialogMessageText: `Are you sure you want to submit this submittal? You can't change the submittal after submitting.`,
        confirmationDialogRightButtonText: 'Submit',
        confirmationDialogSuccessAction: this.onSubmitSubmittal,
      })
    }
  }

  onSubmitSubmittal = async () => {
    if (this.state.submittal && this.state.submittal.name) {
      var submittal = await apiService.submitSubmittal(this.state.submittal.name);
      this.setState({
        ...this.state,
        submittal,
        showConfirmationDialog: false
      });

      this.props.history.push('/');
    }
  }

  render() {
    const { files,
      submittal
    } = this.state;

    const {
      history,
      scopes
    } = this.props;

    const displayMode = submittal && submittal.status === "Open"
      ? DisplayMode.Edit
      : DisplayMode.ReadOnly

    const styles = mergeStyleSets({
      root: {
        width: 1294
      }
    });

    const isValid = files.every(f => f.isDocumentNumberValid === "yes");

    return (
      <>
        <ConfirmationDialog
          history={history}
          leftButtonText='Cancel'
          rightButtonText={this.state.confirmationDialogRightButtonText}
          title={this.state.confirmationDialogTitleText}
          message={this.state.confirmationDialogMessageText}
          show={this.state.showConfirmationDialog}
          onHide={this.onHideDialog}
          onSuccess={this.state.confirmationDialogSuccessAction}
        />

        <DashboardModal
          uppy={this.uppy}
          onRequestClose={this.uppyOnRequestClose}
          open={this.state.uppyModalOpen}
          width={600}
          height={400} />

        <Stack className={styles.root} tokens={{ childrenGap: 10, padding: 's2' }}>

          <Card>
            <Card.Header>

              <Container style={{ margin: '0px' }}>
                <Row>
                  <Col>
                    {this.state.submittalId}
                  </Col>
                  <Col md="auto">{this.state.submittal && this.state.submittal.status}</Col>
                </Row>
              </Container>
            </Card.Header>
            <Card.Body>
              <SubmittalForm
                submitId={this.state.submittalId}
                submitLabel="Save"
                displayMode={displayMode}
                scopes={scopes} />
            </Card.Body>
          </Card>

          <CommandBar
            items={[
              {
                key: 'upload',
                name: 'Add documents',
                iconProps: {
                  iconName: 'Add'
                },
                onClick: this.uppyOnRequestOpen,
                disabled: displayMode === DisplayMode.ReadOnly
              },
              {
                key: 'submit',
                name: 'Submit',
                iconProps: {
                  iconName: 'CloudUpload'
                },
                onClick: () => this.submitSubmittal(),
                disabled: displayMode === DisplayMode.ReadOnly || !isValid
              }
            ]}
            farItems={[
              {
                key: 'delete',
                name: 'Delete',
                iconProps: {
                  iconName: 'Delete'
                },
                onClick: () => this.showDeleteDialogForSubmittal(),
                disabled: displayMode === DisplayMode.ReadOnly
              }
            ]}
          />

          <Card>
            <Card.Header>
              Documents
            </Card.Header>
            <Card.Body>
              {this.renderFiles(files, displayMode)}
            </Card.Body>
          </Card>
        </Stack>
      </>
    );
  }

  renderFiles = (files: BlobMetadata[], displayMode: DisplayMode) => {
    const classNames = mergeStyleSets({
      fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
      },
      fileIconCell: {
        textAlign: 'center',
        selectors: {
          '&:before': {
            content: '.',
            display: 'inline-block',
            verticalAlign: 'middle',
            height: '100%',
            width: '0px',
            visibility: 'hidden'
          }
        }
      },
      fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: 'none',
        maxWidth: 'none'
      },
      warningIcon: {
        color: 'orange'
      }
    });

    const columns: IColumn[] = [
      {
        key: 'fileTypeColumn',
        name: 'File Type',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'name',
        minWidth: 16,
        maxWidth: 16,
        onRender: (item: BlobMetadata) => {
          return <Icon
            {...getFileTypeIconProps({ extension: item.name.split('.').pop(), size: 16, imageFileType: 'svg' })}
            className={classNames.fileIconImg}
          />
        }
      },
      {
        key: 'nameColumn',
        name: 'Document Name',
        fieldName: 'name',
        minWidth: 380,
        maxWidth: 380,
        isResizable: true,
        isCollapsable: true,
        isSorted: true,
        isSortedDescending: false,
        data: 'string',
        isPadded: false
      },
      {
        key: 'documentNumberColumn',
        name: 'Document No.',
        fieldName: 'documentNumber',
        minWidth: 240,
        maxWidth: 240,
        isResizable: true,
        data: 'string',
        onRender: (item: BlobMetadata) => {
          return <span>{item.documentNumber}</span>;
        },
        isPadded: false
      },
      {
        key: 'revisionNumberColumn',
        name: 'Rev No.',
        fieldName: 'revisionNumber',
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        isCollapsible: true,
        data: 'number',
        onRender: (item: BlobMetadata) => {
          return displayMode === DisplayMode.Edit ?
            <TextField
              value={item.revisionNumber}
              onChange={(event, newValue) => this.updateMetadata(item, 'revisionNumber', newValue)}
            /> :
            <span>{item.revisionNumber}</span>
        },
        isPadded: false
      },
      {
        key: 'releaseColumn',
        name: 'Release',
        fieldName: 'release',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onRender: (item: BlobMetadata) => {
          return displayMode === DisplayMode.Edit ?
            <ComboBox
              selectedKey={item.release}
              autoComplete="on"
              options={
                docReleaseOptions.map(o => {
                  return { key: o, text: o }
                }
                )
              }
              onChange={(event, option) => this.updateMetadata(item, 'release', option!.key)}
            /> :
            <span>{item.release}</span>
        }
      },
      {
        key: 'purposeColumn',
        name: 'Purpose',
        fieldName: 'purpose',
        minWidth: 140,
        maxWidth: 140,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onRender: (item: BlobMetadata) => {
          return displayMode === DisplayMode.Edit ?
            <ComboBox
              selectedKey={item.purpose}
              autoComplete="on"
              options={
                docPurposeOptions.map(o => {
                  return { key: o, text: o }
                }
                )
              }
              onChange={(event, option) => this.updateMetadata(item, 'purpose', option!.key)}
            /> :
            <span>{item.purpose}</span>
        }
      },
      {
        key: 'actionColumn',
        name: ' ',
        fieldName: 'name',
        minWidth: 16,
        maxWidth: 34,
        isResizable: false,
        isCollapsible: false,
        data: 'string',
        onRender: (item: BlobMetadata) => {
          return displayMode === DisplayMode.Edit ?
            <>
              <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={() => this.showDeleteDialogForDocument(item.name)} />
              {item.isDocumentNumberValid === "unverified" && <Spinner size={SpinnerSize.xSmall} />}
              {item.isDocumentNumberValid === "no" && <IconButton iconProps={{ iconName: 'Warning' }} title="Warning" ariaLabel="Warning" />}
            </> : null;
        }
      }
    ];

    return (
      <DetailsList
        items={files.sort((a, b) => a.name.localeCompare(b.name))}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        selectionMode={SelectionMode.none}
      />
    );
  }

  extractDocumentNumber = (fileName: string): string => {
    const { vendor } = this.state;

    if (!vendor)
      return '';
    
    var fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf("."));
    var documentNumberLength = documentNumberLengths.filter(dnl => dnl.location === vendor.location)[0].length;

    return fileNameWithoutExtension.length <= documentNumberLength
      ? fileNameWithoutExtension
      : fileNameWithoutExtension.substring(0, documentNumberLength);
  }

  updateMetadata = async (source: BlobMetadata, propertyName: string, value: any): Promise<void> => {
    let files = this.state.files.map(f =>
      f.name === source.name
        ? { ...f }
        : f
    );

    var file = files.find(f => f.name === source.name);

    if (this.state.submittal && file) {
      file[propertyName] = value;
      await blobService.updateMetadata(this.state.submittal, file);
    }

    this.setState({files});
  }

  verifyDocumentNumbers = async (submittal: Submittal, files: BlobMetadata[]): Promise<void> => {
    files.forEach(async (f) => await this.verifyDocumentNumber(submittal, f));
  }

  verifyDocumentNumber = async (submittal: Submittal, file: BlobMetadata): Promise<void> => {
    let isDocumentNumberValid: "yes" | "no" = file.documentNumber && await apiService.getDocumentNumber(file.documentNumber)
      ? "yes"
      : "no";
    blobService.updateMetadata(submittal, { ...file, isDocumentNumberValid });

    let files = this.state.files.map(f =>
      f.name === file.name
        ? { ...f, isDocumentNumberValid }
        : f
    );
    this.setState({ ...this.state, files });
  }
}