import React, { Component, SyntheticEvent } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { IUser } from '../models/User';

interface INavMenuProps extends RouteComponentProps {
  me: IUser | undefined;
}

class NavMenu extends Component<INavMenuProps> {
  displayName = NavMenu.name
  
  navigate = (k: string, e: SyntheticEvent<{}, Event>) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.history.push(k);
  }

  render() {
    var { me } = this.props;

    return (
      <>
        <Navbar fixed="top">
          <Navbar.Brand href="/">
            <img src="https://www.aurubis.com/binaries/content/gallery/aurubisrelaunch/icons/aurubis-logo.svg"
              className="d-block"
              alt="Aurubis logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav variant="pills" className="mr-auto" defaultActiveKey="/" onSelect={this.navigate}>
              <Nav.Item>
                <Nav.Link href="/">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/submittals">Submittals</Nav.Link>
              </Nav.Item>
              {
                me && me.isPrivileged &&
                <Nav.Item>
                  <Nav.Link href="/vendors">Vendors</Nav.Link>
                </Nav.Item>
              }
            </Nav>
            <span>
              Signed in as: <a href="#login">{me && me.displayName}</a>
            </span>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

export default withRouter(NavMenu);